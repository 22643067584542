.embed-form {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	grid-row: 2/-1;
	
	* {
		width: 100%;
		// height: 100%;
		border: none;
	}
}
