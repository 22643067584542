.loading-icon {
	padding: 20px;

	span {
		background-color: $yellow !important;
	}
}

// #region Color Templates
.green {
	background-color: $green;
	color: $white-white;
}

.yellow {
	background-color: $yellow;
	color: $black;
}

.red {
	background-color: $red;
	color: $white-white;
}

.blue {
	background-color: $blue;
	color: $white-white;
}

.white {
	background-color: $white-white;
	color: $black;
}

.grey {
	background-color: $grey;
	color: $white-white;
}

.black {
	background-color: $black;
	color: $white-white;
}

.dark-black {
	background-color: $dark-black;
	color: $white-white;
}
// #endregion
