.container_profiles {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.container_member {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 20px;

	img {
		object-fit: cover;
		width: 150px;
		height: 150px;
		border-radius: 50%;
		padding: 10px;
	}
}
