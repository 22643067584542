.social-list {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	padding: 10px 0;
	margin: 0;

	z-index: 100;
	// box-shadow: 0px 3px 7px $black;
	background-color: $dark-white;

	.social-item-container {
		width: 30%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.social-item {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		padding: 0px !important;
		margin: 10px 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		img {
			height: 50px;
			width: 50px;
		}
	}

	@mixin circle {
		img {
			height: 30px;
			width: 30px;
		}
	}

	.discord-icon {
		@include circle;
		background-color: $discord-purple;
	}

	.instagram-icon {
		@include circle;
		background-color: $instagram-pink;
	}

	.email-icon {
		background-color: $black;
		font-size: 1.5rem;
	}
}
