@import "variables";
@import "fonts";
@import "constants";
@import "header";
@import "footer";
@import "social";
@import "homepage";
@import "forms";
@import "games";
@import "team";
@import "blog";
@import "privacy-policy";

body {
	margin: 0;
	text-align: center;
	font-family: "Raleway", sans-serif;
	background-color: $black-blue;
	min-height: 100vh;
	color: $white-white;
}

#root {
	min-height: 100vh;
	display: grid;
	grid-template-rows: $header-height calc(100% - 70px - $footer-height) $footer-height;
}

a {
	color: $white-white;
	text-decoration: none;
}

a:hover {
	color: $yellow;
}

a:active {
	color: $yellow;
}

iframe {
	border: 0;
}

.space {
	font-size: 0.5em;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.highlight {
	font-weight: 700;
	color: $yellow;
}

.container {
	grid-row: 2/3;
	z-index: 0;
}

.full-width {
	width: 100% !important;
}

@media (max-width: 768px) {
	#root {
		grid-template-rows: $header-height calc(100% - 70px - 110px) 110px;
	}
}

.button {
	margin: 10px auto;
	padding: 12px 20px;
	background: $blue;
	color: $white-white;
	border-radius: 7px;

	font-size: 1.3em;
	font-weight: 700;

	&:hover {
		color: $white !important;
		background: $white-black;
	}
}
