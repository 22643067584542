@font-face {
	font-family: "Viceroy of Deacons";
	font-style: normal;
	font-weight: 400;
	// src: url('../fonts/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local("Viceroy of Deacons"), local("Viceroy of Deacons"),
		url("../fonts/viceroy_of_deacons/viceroy.ttf") format("truetype"); /* Safari, Android, iOS */
	//  url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'),   IE6-IE8
	//  url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'),   Super Modern Browsers
	//  url('../fonts/open-sans-v13-latin-regular.woff') format('woff'),   Modern Browsers
	//  url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
