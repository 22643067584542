.container_blog {
	font-size: calc(10px + 2vmin);
	background: $black-blue;
	grid-row: 2/3;
	padding: 30px;

	h1 {
		font-size: 1.5em;
	}
}

.container_articles {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
}

.container_article {
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 15vw;
	width: 300px;
}

.article-button {
	background: none;
	border: 2px solid $white-white;
	padding: 20px 40px;
	height: 100px;

	color: $black;
	background-color: $yellow;
	cursor: pointer;
	transition: 0.4s;
}

.container_article:hover {
	// background-color: $white-yellow;
	box-shadow: 4px 10px 10px $black;
	transition: .2s;
}

.article-title {
	font-size: 2em;
	padding: 0;
	margin: 0;
}

.hidden {
	display: none;
}

.article-content {
	width: 100%;
	padding: 15px 0;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;

	background-color: $grey;
}
