.container_footer {
	grid-row: -2/-1;
	grid-column: 1/-1;

	line-height: 2em;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	min-height: 100%;

	.legal {
		padding: 10px 0;
	}
}

.footer-links {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	* {
		padding: 0px 5px;
	}
}

@media (max-width: 768px) {
	.container_footer {
		padding: 20px 0;
		height: fit-content;
	}
}
