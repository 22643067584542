.navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	background-color: $dark-blue;
	box-shadow: 0px 1px 20px 3px $black;
	font-size: calc(10px + 1vmin);

	&_items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		& a {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100%;

			font-weight: 500;
			font-size: 1.2em;
			padding: 0 20px;

			&.logo {
				&:hover {
					color: $white-white;
				}

				.title {
					font-family: $viceroy;
					text-align: left;
					padding-left: 10px;
					font-size: 1.5em;
					font-weight: 300;
				}

				img {
					max-height: 40px;
				}
			}
		}
	}
}

.mobile-nav {
	height: $header-height;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.logo {
		display: flex;
		align-items: center;
		height: 100%;

		&:hover {
			color: $white-white;
		}

		.title {
			font-family: $viceroy;
			text-align: left;
			padding-left: 10px;
			font-size: 1.5em;
			font-weight: 300;
		}

		img {
			max-height: 40px;
		}
	}

	.menu-bars:hover,
	.menu-bars:active,
	.menu-bars:focus {
		color: $white !important;
		background-color: transparent !important;
	}

	.menu-bars {
		border-radius: 5px;
		border: none;
		color: $white !important;
		background-color: $blue !important;
		font-size: 1.5em;
		padding: 5px 20px;
		margin: 10px;
		align-self: flex-start;
	}
}

@media (min-width: 769px) {
	.mobile-nav {
		display: none;
	}

	.navbar {
		height: $header-height;
	}
}

@media (max-width: 768px) {
	.mobile-nav {
		height: $header-height;
	}

	.navbar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		&_items {
			flex-direction: column;
			align-items: flex-start;

			padding-bottom: 10px;

			& a {
				padding: 0px;
				margin: 10px 0;
				width: 100%;
			}
		}
		&.collapsed {
			.navbar_items {
				display: none;
				height: 0;
			}
		}
	}
}
