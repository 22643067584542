// #region Layout
$header-height: 70px;
$footer-height: 200px;
// #endregion

// #region Fonts
$baloo: "Baloo Chettan 2";
$viceroy: "Viceroy of Deacons", sans-serif;
// #endregion

// #region Transitions
$transition-fast: all 0.25s;
// #endregion

// #region Colors
$instagram-pink: #ee0173;
$kickstarter-green: #05ce78;
$discord-purple: #5865f2;

$white-red: #f49090;
$red: #e71717;
$dark-red: #770000;
$black-red: #440000;

$white-orange: #f6bb8d;
$orange: #ed7113;
$dark-orange: #903c00;
$black-orange: #582500;

$white-yellow: #ffe785;
$yellow: #ffcc00;
$dark-yellow: #a67900;
$black-yellow: #5d4300;

$white-green: #64e985;
$green: #00b62d;
$dark-green: #096600;
$black-green: #004210;

$white-blue: #49a6e9;
$blue: #1987d6;
$dark-blue: #06457f;
$black-blue: #00305a;

$white-purple: #c2a3e1;
$purple: #7a3cb9;
$dark-purple: #4a2281;
$black-purple: #2b1943;

$white-pink: #fcbacb;
$pink: #ff8fbc;
$dark-pink: #a24d6f;
$black-pink: #58293c;

$white-brown: #d8ac8f;
$brown: #7f4c24;
$dark-brown: #4f3017;
$black-brown: #2e1d0f;

$white-white: #fdfdfd;
$white: #ebebeb;
$dark-white: #b8babd;
$black-white: #a4a8ac;

$white-grey: #999b9d;
$grey: #717475;
$dark-grey: #4e5458;
$black-grey: #2b343b;

$white-black: #1e272e;
$black: #1a1f24;
$dark-black: #111518;
$black-black: #080a0c;
// #endregion
