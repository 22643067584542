.container_homepage {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	font-size: calc(10px + 2vmin);
	color: $white-white;

	grid-row: 2/3;
}

.banner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 400;

	padding: 80px 0;
	height: -webkit-fill-available;
	// box-shadow: 0px 3px 7px $black;
	// background-color: $dark-blue;
	background-color: $black-blue;

	h1 {
		margin: 0;
		padding: 0;
	}

	&_img {
		width: 20%;
		padding: 15px 30px;
	}

	&_title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&_name {
			font-family: $viceroy;
			font-size: 10vw;

			margin: 0;
			padding: 0;
			font-weight: normal;
		}

		&_tagline {
			font-size: 2vw;
			line-height: 1em;
			text-transform: uppercase;
		}
	}
}

.content-container {
	width: 100% !important;

	p {
		margin: 0;
	}
}

.side-border {
	height: 100%;
	width: 100%;

	grid-row: 1/-1;
	grid-column: 1/-2;
}

.mid {
	// clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
	clip-path: polygon(90% 0, 100% 0%, 50% 100%, 40% 100%);

	background-color: $yellow;
	// z-index: 100;
}

.forward {
	clip-path: polygon(0 0, 90% 0, 40% 100%, 0% 100%);
	background-color: $black;
	// z-index: 200;
}

.welcome-message {
	// box-shadow: 0px 3px 7px $black;
	z-index: 300;

	display: grid;
	grid-template-rows: 1fr 4fr 1fr;
	grid-template-columns: 5% 5% 5% 85%;

	text-align: left;
	background-color: $dark-blue;
	color: $white-white;

	div {
		grid-row: 2/-2;
		grid-column: -2/-1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	h1 {
		margin: 0;
	}

	p {
		display: flex;
		flex-direction: column;
		text {
			padding-bottom: 7px;
			font-size: 0.8em;
		}
	}

	h1,
	p {
		padding: 10px;
	}
}

.pillars-container {
	display: flex;
}

@media (min-width: 1025px) {
	.container_homepage {
		justify-content: center;
	}
	.banner {
		flex-direction: row;
	}
}

@media (max-width: 1024px) {
	.banner {
		padding-top: 0px !important;

		&_img {
			padding: 15px 30px;
		}
	}
}

@media (max-width: 1024px) and (min-width: 425px) {
	.banner {
		&_img {
			width: 420px;
		}
		&_title {
			&_name {
				font-size: 12vw;
			}

			&_tagline {
				font-size: 5vw;
			}
		}
	}
}

@media (max-width: 425px) {
	.banner {
		&_img {
			width: 80%;
		}

		&_title {
			&_name {
				font-size: 15vw;
			}

			&_tagline {
				font-size: 8vw;
			}
		}
	}
}
