.container_games_etf {
	background-color: $white-black;
}

.game-banner {
	padding: 70px 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	// background: linear-gradient(103deg, $blue, $black-black, $black-black, $black-black, $yellow);
	background: url("../../public/assets/ETF/ETF-background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);

	img {
		padding-left: 30px;
	}

	.description {
		text-shadow: 2px 4px 8px black;
	}

	.button {
		margin: 10px;
		padding: 20px;
		font-size: 1.8em;
		box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
		border-radius: 20px;

		i {
			padding-left: 15px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.etf-logo {
		max-height: 300px;
		max-width: 80vw;
	}

	.steam-logo {
		position: relative;
		top: 10px;

		height: 30px;
		padding: 0px 10px;
	}

	a.game-title {
		text-shadow: 5px 7px 15px rgba(0, 0, 0, 0.8);
		transition: $transition-fast;
		color: $white;

		&:hover {
			color: $yellow;
			text-shadow: 7px 14px 15px rgba(0, 0, 0, 0.8);
		}

		> h1 {
			font-family: "Baloo Chettan 2", cursive;
			font-weight: 700;
			font-size: calc(10px + 25vmin);
			margin: 10px;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			font-style: italic;
			line-height: 0.8em;

			i {
				font-style: italic;
			}

			.title-text {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				line-height: 0.8em;
				font-size: 0.4em;

				$childpadding: 32px;
				> :first-child {
					padding-left: calc($childpadding * 2);
				}
				> :nth-child(2) {
					padding-left: $childpadding;
				}
			}
		}
	}

	.title-tagline {
		font-size: 2em;
		margin: 0;
	}
}

.signup {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 10px 20px;

	background-color: $yellow;
	color: $black-black;

	> * {
		margin: 10px;
	}

	h2 {
		text-align: right;
		// font-weight: 400 !important;
		max-width: 45vw;
		margin: auto 10px;
		padding: 10px;
	}

	.steam-embed {
		width: 100%;
		height: max-content;
		min-height: 190px;
		max-width: 800px;
	}

	.mobile-cta {
		display: none;
		color: white;

		i {
			font-size: 0.8em;
			padding-left: 4px;
		}
	}
}

.content-section {
	min-height: 20vh;
	padding: 50px 10vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	font-weight: 700;
	color: $white-white;

	* {
		max-width: 1200px;
	}

	i {
		margin: 3px;
	}

	.checklist-item {
		margin-left: 17px;
		text-align: left;
	}

	h2 {
		font-size: calc(4vmin);
		text-align: center;
		width: 70vw;
	}

	.button {
		margin-top: 30px;
		background-color: $yellow;
		color: $black;
	}

	&_text {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		ul {
			list-style: none;
			padding: 0;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		li {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;

			padding: 7px 15px;
			width: -webkit-fill-available;

			box-sizing: border-box;

			font-size: 1.2em;
		}
	}

	&.cta {
		flex-direction: column;
		justify-content: center;

		background-color: $dark-black;

		h2 {
			margin-bottom: 20px;
			width: initial;
			text-align: center;
			font-size: calc(1.5em + 2vmin);
		}

		h3 {
			font-size: calc(0.9em + 2vmin);
			margin-right: 20px;
		}

		.button {
			margin: 0;
			font-size: 1.5em;
		}

		.button-container {
			height: fit-content;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		}
	}

	.video {
		width: 100%;
		height: 45vw;
		max-height: 672px;
		transition: $transition-fast;

		&:hover {
			box-shadow: 5px 7px 35px rgba(0, 0, 0, 0.8);
		}
	}
}

.etf-pitch {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 30px 0 50px;
	background-color: $black-grey;

	a {
		color: $blue;
		max-width: fit-content;
	}
}

@media (max-width: 908px) and (min-width: 769px) {
	.signup {
		flex-direction: column;

		h2 {
			text-align: center;
			max-width: 95vw;
			font-size: calc(1em + 1vmin);
		}

		.steam-embed {
			max-width: 600px;
		}
	}
}

@media (max-width: 768px) {
	.game-title {
		font-size: calc(10px + 30vmin);
	}

	.game-banner {
		.button {
			font-size: 1.4em;
		}
	}

	.content-section {
		padding: 50px 5vw;

		h2 {
			font-size: calc(9vmin);
			width: 90vw;
		}

		&_text {
			ul {
				width: 90vw;

				flex-direction: column;
				justify-content: flex-start;
			}

			li {
				width: 90vw;
			}
		}

		&.cta {
			flex-direction: column;
			justify-content: center;
			padding-left: 5vw;

			h2 {
				margin: 0;
				width: initial;
				text-align: center;
			}

			.button {
				margin: 0;
			}

			.button-container {
				padding: 20px;
				border: none;
				height: fit-content;
			}
		}
	}

	.signup {
		flex-direction: column;

		h2 {
			display: none;
		}

		.steam-embed {
			display: none;
		}

		.mobile-cta {
			display: initial;
		}
	}
}
